import { Card, Checkbox, Col, DatePicker, Form, InputNumber, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { FormInstance, Rule } from "antd/lib/form";
import { Moment } from "moment";
import ReactQuill from "react-quill";
import t from "../../../../../../app/i18n";
import InputAddon from "../../../../../../common/components/form/addons/InputAddon";
import LabelWithTooltip from "../../../../../../common/components/form/labels/LabelWithTooltip";
import { rowGutter } from "../../../../../../common/constants";
import {
  datePickerClearableProps,
  datePickerStandardProps,
  disableDatePickerOutOfMaxDate,
  disableDatePickerOutOfMaxDateIncluded,
  disableDatePickerOutOfMinDate,
  disableDatePickerOutOfMinDateIncluded,
  inputNumberDecimalStandardProps,
  quillEditorStandardProps,
  toMoment
} from "../../../../../../common/utils/formUtils";
import { validations } from "../../../../../../common/utils/validationUtils";
import { CreateUpdateContractGainerRecord, CreateUpdateDepositContract } from "../../../../types";
import { calculateContractStatus } from "../../../../utils";
import ContractStatusTag from "../../../ContractStatusTag";

interface Props {
  form: FormInstance<CreateUpdateDepositContract>;
}

const DepositContractFormDataSection = ({ form }: Props) => {
  const handleSignDateChange = (): void => {
    handleStatusDefiningDateChange();
    const signDate = toMoment(form.getFieldValue(["signDate"])) as Moment;
    const records = [...form.getFieldValue(["gainerRecords"])] as CreateUpdateContractGainerRecord[];
    records[0] = { ...(records[0] as CreateUpdateContractGainerRecord), startDate: signDate };
    form.setFieldsValue({ gainerRecords: records });
  };

  const handleStatusDefiningDateChange = (): void => {
    const { signDate, effectiveEndDate, cancellationDate, transferredToOtherBrokerDate } = form.getFieldsValue([
      ["signDate"],
      ["effectiveEndDate"],
      ["cancellationDate"],
      ["transferredToOtherBrokerDate"]
    ]) as CreateUpdateDepositContract;
    form.setFieldsValue({
      status: calculateContractStatus({
        startDate: signDate as Moment,
        endDate: effectiveEndDate as Moment,
        cancellationDate: cancellationDate as Moment,
        transferredToOtherBrokerDate: transferredToOtherBrokerDate as Moment
      })
    });
  };

  const handleTransferredToOtherBrokerChange = (event: CheckboxChangeEvent): void => {
    if (!event.target.checked) {
      form.setFieldsValue({ transferredToOtherBrokerDate: undefined });
      handleStatusDefiningDateChange();
    }
  };

  const colSpan = 4;

  return (
    <Card
      type="inner"
      className="card-box card-box--inner-extra"
      title={t("contract.sections.contractData")}
      extra={
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.status !== next.status}>
          {({ getFieldValue }) => {
            const status = getFieldValue("status");
            return status ? <ContractStatusTag status={status} /> : undefined;
          }}
        </Form.Item>
      }
    >
      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.signDate !== next.signDate}>
            {({ getFieldValue }) => {
              const signDate = getFieldValue("signDate");
              const rules: Rule[] = [validations.notNull];
              if (signDate) {
                rules.push(validations.notAfter(signDate, t("contract.attrs.signDate")));
              }
              return (
                <Form.Item
                  name="mediationReportSignDate"
                  label={t("contract.attrs.mediationReportSignDate")}
                  rules={rules}
                >
                  <DatePicker
                    {...datePickerStandardProps}
                    disabledDate={current => (signDate ? disableDatePickerOutOfMaxDate(current, signDate) : false)}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item name="signDate" label={t("contract.attrs.signDate")} rules={[validations.notNull]}>
            <DatePicker {...datePickerStandardProps} onChange={handleSignDateChange} />
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.signDate !== next.signDate}>
            {({ getFieldValue }) => {
              const signDate = getFieldValue("signDate") as Moment;
              return (
                <Form.Item
                  name="effectiveEndDate"
                  label={t("contract.attrs.effectiveEndDate")}
                  rules={[
                    signDate ? validations.notSameOrBefore(signDate, t("contract.attrs.signDate")) : validations.none
                  ]}
                >
                  <DatePicker
                    {...datePickerClearableProps}
                    disabledDate={current =>
                      signDate ? disableDatePickerOutOfMinDateIncluded(current, signDate) : false
                    }
                    onChange={handleStatusDefiningDateChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.signDate !== next.signDate || prev.effectiveEndDate !== next.effectiveEndDate
            }
          >
            {({ getFieldValue }) => {
              const signDate = getFieldValue("signDate") as Moment;
              const effectiveEndDate = getFieldValue("effectiveEndDate") as Moment;
              const rules = [];
              if (signDate) {
                rules.push(validations.notBefore(signDate, t("contract.attrs.signDate")));
              }
              if (effectiveEndDate) {
                rules.push(validations.notSameOrAfter(effectiveEndDate, t("contract.attrs.effectiveEndDate")));
              }
              return (
                <Form.Item
                  name="cancellationDate"
                  label={t("contract.attrs.cancellationDate")}
                  rules={rules.length > 0 ? rules : [validations.none]}
                >
                  <DatePicker
                    {...datePickerClearableProps}
                    disabledDate={current =>
                      (signDate && disableDatePickerOutOfMinDate(current, signDate)) ||
                      (effectiveEndDate && disableDatePickerOutOfMaxDateIncluded(current, effectiveEndDate))
                    }
                    onChange={handleStatusDefiningDateChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="targetAmount"
            label={t("contract.attrs.targetAmount")}
            rules={[validations.notNull, validations.minNumber(0)]}
          >
            <InputNumber {...inputNumberDecimalStandardProps} addonAfter={<InputAddon type="euro" />} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={colSpan}>
          <Form.Item
            name="transferredFromOtherBroker"
            className="two-line-form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox>
              <LabelWithTooltip
                label={t("contract.attrs.transferredFromOtherBroker")}
                tooltip={t("contract.helpers.transferredFromOtherBrokerDesc")}
              />
            </Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            name="transferredToOtherBroker"
            className="two-line-form-item-without-label"
            valuePropName="checked"
            rules={[validations.none]}
            initialValue={false}
          >
            <Checkbox onChange={handleTransferredToOtherBrokerChange}>
              <LabelWithTooltip
                label={t("contract.attrs.transferredToOtherBroker")}
                tooltip={t("contract.helpers.transferredToOtherBrokerDesc")}
              />
            </Checkbox>
          </Form.Item>
        </Col>

        <Col span={colSpan}>
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) =>
              prev.signDate !== next.signDate ||
              prev.effectiveEndDate !== next.effectiveEndDate ||
              prev.cancellationDate !== next.cancellationDate ||
              prev.transferredToOtherBroker !== next.transferredToOtherBroker
            }
          >
            {({ getFieldValue }) => {
              const signDate = getFieldValue("signDate") as Moment;
              const endDate = getFieldValue("effectiveEndDate") as Moment;
              const cancellationDate = getFieldValue("cancellationDate") as Moment;
              const transferredToOtherBroker = getFieldValue("transferredToOtherBroker");
              const rules = [];
              if (signDate) {
                rules.push(validations.notSameOrBefore(signDate, t("contract.attrs.signDate")));
              }
              if (cancellationDate) {
                rules.push(validations.notAfter(cancellationDate, t("contract.attrs.cancellationDate")));
              } else if (endDate) {
                rules.push(validations.notAfter(endDate, t("contract.attrs.effectiveEndDate")));
              }
              if (transferredToOtherBroker) {
                rules.push(validations.notNull);
              }
              return (
                <Form.Item
                  name="transferredToOtherBrokerDate"
                  label={
                    <LabelWithTooltip
                      label={t("contract.attrs.transferredToOtherBrokerDate")}
                      tooltip={t("contract.helpers.transferredToOtherBrokerDateDesc")}
                    />
                  }
                  rules={rules.length > 0 ? rules : [validations.none]}
                >
                  <DatePicker
                    {...datePickerClearableProps}
                    disabled={!transferredToOtherBroker}
                    disabledDate={current =>
                      (signDate && disableDatePickerOutOfMinDateIncluded(current, signDate)) ||
                      (cancellationDate && disableDatePickerOutOfMaxDate(current, cancellationDate)) ||
                      (endDate && disableDatePickerOutOfMaxDate(current, endDate))
                    }
                    onChange={handleStatusDefiningDateChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={rowGutter}>
        <Col span={24}>
          <Form.Item
            name="note"
            label={t("contract.attrs.note")}
            rules={[validations.size(1, 8192)]}
            initialValue={undefined}
          >
            <ReactQuill {...quillEditorStandardProps} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default DepositContractFormDataSection;
